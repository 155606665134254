import { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DEFAULT_TEMPLATE_ID, PRICES_FOR_PAYMENT_ID } from 'utils/constants';
import { AuthCookieContext } from 'utils/context';

export const TemplateWrapper = () => {
  const { userInfo, setUserInfo } = useContext(AuthCookieContext);
  const { templateId } = useParams();
  if (templateId && userInfo?.templateId !== templateId) {
    setUserInfo((prev) => ({
      ...prev,
      templateId: Object.keys(PRICES_FOR_PAYMENT_ID).includes(templateId)
        ? templateId
        : DEFAULT_TEMPLATE_ID,
    }));
  }
  return <Outlet />;
};
