import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { clickToScroll } from '../utils/helpers';

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    clickToScroll('root > .MuiContainer-root');
  }, []);

  return (
    <Container
      maxWidth='xs'
      disableGutters
      sx={{
        height: '100vh',
        px: 20 / 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold' }}>
          404
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary">
          Oops! Page not found
        </Typography>
        <Typography align="center" color="text.secondary">
          The page you are looking for might have been removed or is temporarily unavailable.
        </Typography>
        <Button
          variant='contained'
          size='large'
          onClick={() => navigate('/')}
        >
          Back to Home
        </Button>
      </Stack>
    </Container>
  );
};

export default NotFound;