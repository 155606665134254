import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthCookieContext } from 'utils/context';
import { PAYMENT_SYSTEM } from 'utils/constants';

const StripeCancelPage = () => {
  const { userInfo } = useContext(AuthCookieContext);
  return (
    <Navigate
      to={`/${userInfo.templateId}/subscriptions?type=${PAYMENT_SYSTEM.STRIPE}`}
    />
  );
};

export default StripeCancelPage;