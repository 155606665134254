export const WEB_NEW_USER = 'web_new_user';
export const WEB_HEATING_INTEREST_STARTED = 'web_heating_interest_started';
export const WEB_HEATING_INTEREST_STARTED_2 = 'web_heating_interest_started_2';
export const WEB_HEATING_INTEREST_STARTED_3 = 'web_heating_interest_started_3';
export const WEB_HEATING_INTEREST_STARTED_4 = 'web_heating_interest_started_4';
export const WEB_HEATING_INTEREST_STARTED_5 = 'web_heating_interest_started_5';
export const WEB_HEATING_INTEREST_STARTED_6 = 'web_heating_interest_started_6';

export const WEB_SHARING_ABOUT_USER_STARTED = 'web_sharing_about_user_started';
export const WEB_SHARING_ABOUT_USER_STARTED_2 =
  'web_sharing_about_user_started_2';
export const WEB_SHARING_ABOUT_USER_STARTED_3 =
  'web_sharing_about_user_started_3';
export const WEB_SHARING_ABOUT_USER_STARTED_4 =
  'web_sharing_about_user_started_4';
export const WEB_SHARING_ABOUT_USER_STARTED_5 =
  'web_sharing_about_user_started_5';
export const WEB_SHARING_ABOUT_USER_STARTED_6 =
  'web_sharing_about_user_started_6';
export const WEB_SHARING_ABOUT_USER_STARTED_7 =
  'web_sharing_about_user_started_7';
export const WEB_SHARING_ABOUT_USER_STARTED_8 =
  'web_sharing_about_user_started_8';
export const WEB_SHARING_ABOUT_USER_STARTED_9 =
  'web_sharing_about_user_started_9';
export const WEB_SHARING_ABOUT_USER_STARTED_10 =
  'web_sharing_about_user_started_10';
export const WEB_SHARING_ABOUT_USER_STARTED_11 =
  'web_sharing_about_user_started_11';
export const WEB_SHARING_ABOUT_USER_STARTED_12 =
  'web_sharing_about_user_started_12';
export const WEB_SHARING_ABOUT_USER_STARTED_13 =
  'web_sharing_about_user_started_13';
export const WEB_SHARING_ABOUT_USER_STARTED_14 =
  'web_sharing_about_user_started_14';
export const WEB_SHARING_ABOUT_USER_STARTED_15 =
  'web_sharing_about_user_started_15';
export const WEB_SHARING_ABOUT_USER_STARTED_16 =
  'web_sharing_about_user_started_16';

export const WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED =
  'web_telling_about_user_preferences_started';
export const WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_2 =
  'web_telling_about_user_preferences_started_2';
export const WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_3 =
  'web_telling_about_user_preferences_started_3';
export const WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_4 =
  'web_telling_about_user_preferences_started_4';
export const WEB_TELLING_ABOUT_USER_PREFERENCES_STARTED_5 =
  'web_telling_about_user_preferences_started_5';

export const WEB_SETTING_USER_GOAL_STARTED = 'web_setting_user_goal_started';
export const WEB_SETTING_USER_GOAL_STARTED_2 =
  'web_setting_user_goal_started_2';
export const WEB_SETTING_USER_GOAL_STARTED_3 =
  'web_setting_user_goal_started_3';

export const WEB_LAST_STEP_ANALYSING_STARTED =
  'web_last_step_analysing_started';
export const WEB_LAST_STEP_ANALYSING_STARTED_3 =
  'web_last_step_analysing_started_3';
export const WEB_LAST_STEP_ANALYSING_STARTED_4 =
  'web_last_step_analysing_started_4';

export const WEB_PAYWALL_OPENED = 'web_paywall_opened';
export const WEB_OFFER_PAYWALL_OPENED = 'web_offer_paywall_opened';
export const WEB_CHECKOUT_OPENED = 'web_checkout_opened';
export const WEB_CHECKOUT_CLICKED = 'web_checkout_clicked';
export const WEB_THANKS_OPENED = 'web_thanks_opened';
export const WEB_PURCHASE_ANALYTICS = 'web_purchase_analytics';
export const WEB_CANCEL_SUBSCRIPTION = 'web_cancel_subscription';
export const WEB_CANCEL_SUBSCRIPTION_ERROR = 'web_cancel_subscription_error';
export const WEB_MODAL_CANCEL_SUBSCRIPTION = 'web_modal_cancel_subscription';
export const WEB_OPEN_MANAGE_SUBSCRIPTION = 'web_open_manage_subscription';
export const WEB_SUBSCRIPTION_STATUS_CHECKED =

  'web_subscription_status_checked';
export const WEB_SUBSCRIPTION_PAUSED = 'web_subscription_paused';
export const WEB_SUBSCRIPTION_CHANGED = 'web_subscription_changed';
export const CW_INIT = 'cw_init';
export const FIRST_OPEN = 'first_open';
export const CW_SEGMENT = 'cw_segment';

// google events

export const GA_EVENTS = {
  CW_SUBSCRIBE: 'cw_subscribe',
};
