import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import appTheme from './theme/theme';
import AnimatedRoutes from 'components/AnimatedRoutes';
import 'swiper/css';
import './swiper.css';
import AuthCookieProvider from 'utils/AuthCookieProvider';

function App() {
  return (
    <AuthCookieProvider>
      <ThemeProvider theme={appTheme}>
        <Suspense fallback={<></>}>
          <BrowserRouter>
            <AnimatedRoutes />
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </AuthCookieProvider>
  );
}

export default App;
