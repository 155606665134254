import React, { useEffect, useState } from 'react';
import { AuthCookieContext } from './context';
import useCookie from './useCookie';
import { getLandingRegister } from './../api/api';
import { defaultsDeep, set } from 'lodash';
import trackAmplitude from '../services/analytics';
import { WEB_NEW_USER } from './AmplitudeTrackEvents';
import {
  DEFAULT_TEMPLATE_ID,
  FLOW_LIST,
  PRICES_FOR_PAYMENT_ID,
} from './constants';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const AuthCookieProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const templateIdStorage = localStorage.getItem('templateId');

  const templateId = Object.keys(PRICES_FOR_PAYMENT_ID).includes(
    templateIdStorage
  )
    ? templateIdStorage
    : DEFAULT_TEMPLATE_ID;

  const [userInfo, setUserInfo] = useState({
    templateId,
    paypal: {
      selectedPaymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.paypal,
      paymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.paypal,
      bestOfferPaymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.paypal,
    },
    stripe: {
      selectedPaymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.stripe,
      paymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.stripe,
      bestOfferPaymentId: PRICES_FOR_PAYMENT_ID?.[DEFAULT_TEMPLATE_ID]?.stripe,
    },
    locale: 'en',
  });

  // if (templateIdParam && userInfo?.templateId !== templateIdParam) {
  //   setUserInfo((prev) => ({
  //     ...prev,
  //     templateId: Object.keys(PRICES_FOR_PAYMENT_ID).includes(templateIdParam)
  //       ? templateIdParam
  //       : DEFAULT_TEMPLATE_ID,
  //   }));
  // }

  useCookie({ data: userInfo });

  const loadUserInfo = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const debug = urlParams.get('debug') || '0';

    // const pathname = window.location.pathname;

    let flow = FLOW_LIST.join('/');
    // if (
    //   !LIST_PAGES.includes(pathname) &&
    //   pathname !== '/' &&
    //   pathname.startsWith('/al:')
    // ) {
    //   flow = `al:${window.location.pathname.replace('/al:', '')}`;
    // }

    getLandingRegister({ debug, flow })
      .then((json) => {
        const newLocale = json?.locale || 'en';
        const oldLocale = localStorage.getItem('smmhub_locale');

        if (oldLocale !== newLocale) {
          localStorage.setItem('smmhub_locale', newLocale);
          dayjs.locale(newLocale);
        }

        i18n.changeLanguage(newLocale);

        const templateId = userInfo?.templateId;

        const paymentIdStripe = PRICES_FOR_PAYMENT_ID?.[templateId]?.stripe;
        const paymentIdPaypal = PRICES_FOR_PAYMENT_ID?.[templateId]?.paypal;

        setUserInfo(
          defaultsDeep(json, {
            templateId,
            paypal: {
              // selectedPaymentId: PRICES_FOR_PAYMENT_ID?.[templateId]?.paypal || process.env.REACT_APP_PAYPAL_1_MONTH,
              selectedPaymentId: paymentIdPaypal,
              paymentId: paymentIdPaypal, // 1 month
              bestOfferPaymentId: paymentIdPaypal, // 6 months
            },
            stripe: {
              // selectedPaymentId: PRICES_FOR_PAYMENT_ID?.[templateId]?.stripe || process.env.REACT_APP_STRIPE_1_MONTH,
              selectedPaymentId: paymentIdStripe,
              paymentId: paymentIdStripe, // 1 month
              bestOfferPaymentId: paymentIdStripe, // 6 months
            },
          })
        );

        if (json.is_first_query) {
          trackAmplitude(WEB_NEW_USER, {
            screen: json.screen_id,
            user_id: json.user_id,
          });
        }
      })
      .then(() => {
        if (!userInfo?.countryCode) {
          fetch('https://ipapi.co/json/')
            .then((r) => r.json())
            .then(({ country_code }) => {
              setUserInfo((prev) => ({
                ...set(prev, 'countryCode', country_code),
              }));
            });
        }
      });
  };

  useEffect(() => {
    loadUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const templateId = userInfo?.templateId;
    if (templateId) {
      localStorage.setItem('templateId', templateId);
      const paymentIdStripe = PRICES_FOR_PAYMENT_ID?.[templateId]?.stripe;
      const paymentIdPaypal = PRICES_FOR_PAYMENT_ID?.[templateId]?.paypal;
      setUserInfo((prev) => ({
        ...set(prev, 'stripe.selectedPaymentId', paymentIdStripe),
        ...set(prev, 'paypal.selectedPaymentId', paymentIdPaypal),
      }));
    }
  }, [userInfo?.templateId]);

  console.log(userInfo);
  return (
    <AuthCookieContext.Provider
      value={{
        userInfo,
        setUserInfo,
        loadUserInfo,
      }}
    >
      {children}
    </AuthCookieContext.Provider>
  );
};

export default AuthCookieProvider;
